body > div:first-child{
  height: 100%;
}
.ant-modal-mask,
.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
}

.ant-modal-content {
  overflow: "hidden";
  min-height: fit-content;
  width: 100%;
}

.ant-modal-content .control-dots {
  display: none;
}

.ant-modal-body {
  padding: 0px;
}

.ant-modal {
  top: 56px;
}

.ant-modal-close-x {
  position: absolute;
  bottom: -5px;
  right: -10px;
  
}

.ant-notification-notice-content {
  display: flex;
  align-self: center;
  justify-self: center;
  position: relative;
}
.ant-notification-notice-with-icon{
  padding-right: 20px;
}
.ant-notification-notice{
  min-height: 40px;
  max-height: unset !important;
}
.ant-notification-notice-message {
  width: 100%;
  margin-left: 25px !important;
  font-size: 14px !important;
  display: flex;
}

.ant-notification-notice-close {
  top: 14px !important;
}

.ant-notification-notice .ant-notification-close-x {
  display: flex;
}

.cs-button > svg {
  width: 20px;
  height: 20px;
}
.cs-message__content{
  background-color: #1890FF !important;
  border-radius: 18px !important;
  padding: 6px 12px !important;
  font-family: Noto Sans JP !important;
}
.cs-message__content *{
  background-color: #1890FF !important;
  color: #fff !important;
  font-size: 14px;
  line-height: 22px;
  font-family: Noto Sans JP !important;

}
.cs-message--incoming .cs-message__content{
  background-color: #F0F0F0 !important;
}
.cs-message--incoming .cs-message__content *{
  background-color: #F0F0F0 !important;
  color: #262626 !important;

}
.scrollbar-container{
  padding: 0px 16px !important;
}
.cs-message{
  margin-top: 16px !important;
}
.cs-message-input__content-editor-container{
  padding: 0px !important;
}

.cs-message__image-content{
  margin: -6px -12px;
}
.cs-message__image-content img{
  width: 255px !important;
  height: 196px;
  border-radius: 4px;
}
.cs-message-separator::after, .cs-message-separator::before{
  height: 0px !important;
}
.cs-message--incoming .cs-message__content-wrapper{
  flex-direction: row !important;
  align-items: flex-end !important;
}
.cs-message--outgoing .cs-message__content-wrapper{
  flex-direction: row-reverse !important;
  align-items: flex-end !important;
}
.cs-message-separator{
  margin-top: 28px !important;
  margin-bottom: 28px;
  color: #8C8C8C !important;
  font-family: Noto Sans JP !important;
  font-size: 12px !important;
  line-height: 20px;
}
.cs-message__sent-time{
  display: block !important;
  color: #8C8C8C !important;
  font-size: 12px;
  line-height: 20px;
  font-family: Noto Sans JP;
}
.cs-message--outgoing .cs-message__sent-time{
  padding-right: 6px;
  padding-left: 0px !important
}
.cs-message--incoming .cs-message__sent-time{
  padding-left: 6px !important
}
.cs-message-input{
  border-color: #F0F0F0 !important;
  padding: 12px 0px !important;
}
.cs-message-input__content-editor-wrapper{
  border-radius: 2px !important;
  background-color: #FFFFFF !important;
  border: 1px solid #D9D9D9 !important;
  padding: 5px 12px !important;
}
.cs-message-input__content-editor-wrapper *{
  background-color: #FFFFFF !important;
}
.cs-button--attachment svg, .cs-button--send svg{
  display: none;
}
.cs-button--attachment{
  background: url("/attachment.svg");
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  border-radius: 0px !important;
  margin: auto !important;
  margin-left: 16px !important;
  margin-right: 6px !important;
  background-position: center;

}
.cs-button--send{
  background: url("/send.svg");
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  border-radius: 0px !important;
  margin: auto !important;
  margin-left: 7px !important;
  margin-right: 10px !important;
  background-position: center;

}
.cs-message__avatar{
  width: auto !important;
  margin-right: 9px !important;
}
.download-icon{
  width: 17px !important;
  height: 16px !important;
  min-width: 17px !important;
  min-height: 16px !important;
}
.cs-message__image-content img, .cs-avatar img{
  object-fit: cover;
}
.react-simple-image-viewer__moda{
  z-index: 3;
}
.cs-message-list {
  flex: 1 1 0;
  flex-basis: 0px;
}
.with-image{
  display: flex;
  margin-top: 16px;
}
.with-image .cs-avatar{
  margin-right: 8px;
}
@media (max-width:768px){
  .cs-message-list__scroll-wrapper>.cs-message{
    max-width: unset !important;
  }
  .cs-message__content{
    max-width: 255px !important;
  }
}
.with-image *{
  background-color: transparent !important;
}

@media (max-width:400px){
  .cs-message__image-content img{
    width: 200px !important;
    height: 150px;
  }
}

.ant-tabs-nav-operations{
  display: none !important;
}
.search-field{
  flex: 1;
  min-width: 200px;
  width: 200px;
}
.react-simple-image-viewer__modal{
  z-index: 3 !important;
}